import { Tree, Modal } from 'antd';
import React, { useState, useEffect } from "react";
import { getAllMenuAPI, getAllMenuListAPI } from '../../../API/api';

const x = 3;
const y = 2;
const z = 1;
const gData1 = [];
const ddd = []

/* 
treeeNode
  {
    title: "标题",
    key: "",
    disabled: ? | true,
    children: ? || []
  }
*/

const generateData = (_level, _preKey, _tns) => {
  const preKey = _preKey || '0';
  const tns = _tns || gData1;

  const children = [];
  for (let i = 0; i < x; i++) {
    const key = `${preKey}-${i}`;
    tns.push({ title: key, key });
    if (i < y) {
      children.push(key);
    }
  }
  if (_level < 0) {
    return tns;
  }
  const level = _level - 1;
  children.forEach((key, index) => {
    tns[index].children = [];
    return generateData(level, key, tns[index].children);
  });
};
generateData(z);

export default function MenuModal(props) {
  const { isShow, handlePOK, handlePCancle, showKey } = props
  const [gData, setGData] = useState([])
  const [isVisible, setIsVisible] = useState(isShow)
  const [saveNodeInfo, setSaveNodeInfo] = useState({})


  useEffect(() => {
    let { isShow } = props
    setIsVisible(isShow)
    getMenus()
  }, [props.isShow])

  const onDragEnter = info => {
    console.log(info);
    // expandedKeys 需要受控时设置
    // setExpandedKeys(info.expandedKeys)
 
  };

  const onDrop = info => {
    console.table(info.node.pos);
    // const dropKey = info.node.key;
    // const dragKey = info.dragNode.key;
    // const dropPos = info.node.pos.split('-');
    // const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // const loop = (data, key, callback) => {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].key === key) {
    //       return callback(data[i], i, data);
    //     }
    //     if (data[i].children) {
    //       loop(data[i].children, key, callback);
    //     }
    //   }
    // };
    // const data = [...gData];

    // // Find dragObject
    // let dragObj;
    // loop(data, dragKey, (item, index, arr) => {
    //   arr.splice(index, 1);
    //   dragObj = item;
    // });

    // if (!info.dropToGap) {
    //   // Drop on the content
    //   loop(data, dropKey, item => {
    //     item.children = item.children || [];
    //     // where to insert 示例添加到头部，可以是随意位置
    //     item.children.unshift(dragObj);
    //   });
    // } else if (
    //   (info.node.props.children || []).length > 0 && // Has children
    //   info.node.props.expanded && // Is expanded
    //   dropPosition === 1 // On the bottom gap
    // ) {
    //   loop(data, dropKey, item => {
    //     item.children = item.children || [];
    //     // where to insert 示例添加到头部，可以是随意位置
    //     item.children.unshift(dragObj);
    //     // in previous version, we use item.children.push(dragObj) to insert the
    //     // item to the tail of the children
    //   });
    // } else {
    //   let ar;
    //   let i;
    //   loop(data, dropKey, (item, index, arr) => {
    //     ar = arr;
    //     i = index;
    //   });
    //   if (dropPosition === -1) {
    //     ar.splice(i, 0, dragObj);
    //   } else {
    //     ar.splice(i + 1, 0, dragObj);
    //   }
    // }
    // // test()
    // setGData(data)
  };
  const extractTitle = (titleNode) => {
    if (titleNode.type !== 'article') {
      titleNode.title = titleNode.name
     titleNode.key = titleNode.id
    } else {
      titleNode.selectable = false
    }
  }
  const generateTitle = (treeNode) => {
    
    extractTitle(treeNode)
    if (treeNode.children && treeNode.children.length > 0) {
      return treeNode.children.map((item) => {
        generateTitle(item)
      })
    }
  }
  const getMenus = async () => {
    const res = await getAllMenuAPI()
    // nodeTree 结构强行转换 (对齐key和title) --- 并添加增改删操作，过滤掉type为article的

    res.map((item) => generateTitle(item))
    setGData(res)
  }
  // 确认保存
  const handleSubmit = () => {
    setIsVisible(false)
    handlePOK(saveNodeInfo)
  }
  // 取消关闭
  const handleCancle = () => {
    setIsVisible(false)
    handlePCancle()
  }
  // 获取选中元素
  const handleWithSelect = (e, info) => {
    console.log('eeeee:',e, info)
    setSaveNodeInfo(info.node)
  }

  const handleWthRightSelect = (e, node) => {
    console.log('====', e,node)
  }
  // 默认选中取父
  return (
    <Modal title="请选择保存目录" visible={isVisible} onOk={handleSubmit} onCancel={handleCancle}>
        <Tree
          className="draggable-tree"
          defaultExpandedKeys={showKey}
          draggable
          blockNode
          showLine={true}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          treeData={gData}
          onSelect={handleWithSelect}
          onRightClick={handleWthRightSelect}
        />
    </Modal>

  );
}