import { Tree, Drawer, Popconfirm, Button, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import ModifyInfo from "../modifyInfo/index";
import { getAllMenuAPI, createMenuAPI, deleteMenuAPI, updateMenuAPI, changeArticlePosAPI, changeCatePosAPI} from '../../../API/api';
import "./index.less";

/* 
treeeNode
  {
    title: "标题",
    key: "",
    disabled: ? | true,
    children: ? || []
  }
*/


export default function HandleCate(props) {
  const { vis, handleCateOK, handleCateCancle, reloadMenu, menus } = props
  const [gData, setGData] = useState([])
  const [modifyData, setModifyData] = useState({})
  const [isModify, setIsModify] = useState(true)
  const [modifyVisible, setModifyVisible] = useState(false)
  useEffect(() => {
    getCateTree()
    setModifyData(modifyData)
  }, [modifyData, menus])

  const onDragEnter = info => {
    // console.log('dragEnter', info);
    // expandedKeys 需要受控时设置
    // setExpandedKeys(info.expandedKeys)
 
  };
  const onDragLeave = info => {
    // console.log('leave',info); // - 区域外
 
  };
  const onDragStart = info => {
    console.log('dragstart',info); // - 区域内
 
  };
  const onDragOver= info => {
    // console.log('over', info);
 
  };
  const onDragEnd= info => {
    console.log('dragend',info);
 
  };

  const onDrop = info => {
    // 获取到拖动的dragNode节点，和拖到的目标node节点 - 组件节点设计原因，拖拽时注意蓝线位置
    // article 需要目标的cate_id 和 自己的article_id
    // cate 需要目标的parent_id 和自己的cate_id, 文章没有parent_id代替的是cate_id
    console.log('被拖拽的node：', info.dragNode)
    console.log('目标', info.node)
    let { dragNode ={ }, node = {} } = info
    let param = {}
    let { type, cate_id = '', article_id = '', } = dragNode
    param.cate_id = type === 'article' ? node.cate_id : cate_id
    param.type = type 
    param.article_id = type === 'article' ? article_id : ''
    param.parent_id = type === 'article' ? cate_id : node.parent_id || node.cate_id
    changeAPI(param)
  };
 

  // 根据参数type判断调用API: 
  const changeAPI = async (param) => {
    let res
    let { type } = param
    if (type === 'article') {
      res = await changeArticlePosAPI({...param})
    } else {
      res = await changeCatePosAPI({...param})
    }
    if (res.data) {
      reloadMenu()
    } else {
      message.error(JSON.stringify(res.message))
    }
    getCateTree()
    // handleCancle()
  }



  // 提取公共操作部分
  const extractTitle = (titleNode) => {
    if (titleNode.type !== 'article') {
      titleNode.title = <span>
      <span className="cateName">{titleNode.name || titleNode.name}</span>
      <span className="editCateName" onClick={handleEditCateName}><EditOutlined/></span>
      <span className="createCate" onClick={handleWithCreateBtn}><PlusOutlined /></span>
      <Popconfirm
       className="deleteCate"
       title="确定要删除该目录吗？"
       onConfirm={confirmDelete}
       onCancel={cancelDelete}
       okText="Yes"
       cancelText="No"
     > <DeleteOutlined /> </Popconfirm>
      </span>
     titleNode.key = titleNode.id
    }
  }
  const generateTitle = (treeNode) => {
    
    extractTitle(treeNode)
    if (treeNode.children && treeNode.children.length > 0) {
      return treeNode.children.map((item) => {
        generateTitle(item)
      })
    }
  }
  const getCateTree = async () => {
    const res = await getAllMenuAPI()
    // nodeTree 结构强行转换 (对齐key和title) --- 并添加增改删操作，过滤掉type为article的
    res.map((item) => generateTitle(item))
    setGData(res)
  }
  const handleWithCateSelect = (select, node) => {
    setModifyData(node.node)
    console.log('====,', modifyData, select, node)
  }
  const handleWthCateRightSelect = (e) => {
    console.log('cateRight:', e)
  }

  // 编辑name
  const handleEditCateName =  () => {
    setIsModify(true)
    setModifyVisible(true)
  }
  // 新建
  const handleWithCreateBtn = () => {
    setIsModify(false)
    setModifyVisible(true)
  }
  const handleEditCateNameOK = async (infos) => {
    setModifyVisible(false)
    let data = {
      name: infos.name,
      weight: modifyData.weight || '',
      id: modifyData.cate_id,
      desc: infos.desc
    }
    const uRes = await updateMenuAPI({...data})
    if (uRes.data) {
      message.success('更改目录名成功～')
      reloadMenu()
      getCateTree()
      // window.location.reload()
    } else {
      message.error(JSON.stringify(uRes.message))
    }
  }
  const handleEditCateNameCacle = () => {
    setModifyVisible(false)
  }
  const handleCreateCateOK = async (infos) => {
      /* name	类目名称	是	[string]
      desc	类目描述	是	[string]
      weight	权重 
      parent_id	上级类目id */
    setModifyVisible(false)
    let data = {
      name: infos.name,
      weight: modifyData.weight || '',
      parent_id: modifyData.cate_id,
      desc: infos.desc
    }
    const cRes = await createMenuAPI({...data})
    if (cRes.data) {
      // window.location.reload()
      getCateTree()
      message.success('创建成功～')
    } else {
      message.error(JSON.stringify(cRes.message))
    }
  }
  const confirmDelete = async (e) => {
    const dRes = await deleteMenuAPI({id: modifyData.cate_id})
    if(dRes.data) {
      message.success('删除目录成功')
      // window.location.reload()
      getCateTree()
      reloadMenu()
    } else {
      message.error(JSON.stringify(dRes.message))
    }
    // handleCateCancle()
  }
  const cancelDelete = (e) => {
    console.log('取消删除 ',e)
    // handleCateCancle()
  }

  const handleSubmit = (e) => {
    handleCateOK()
  }
  const handleCancle = (e) => {
    handleCateCancle()
    // window.location.reload()
  }
  
  // 默认选中取父
  return (
    <>
      <Drawer 
        title="请谨慎操作目录"
        visible={vis}
        placement="right"
        width="400px"
        closable={false}
        onClose={handleCancle}>
          <Tree
            className="draggable-tree"
            draggable
            // blockNode
            showLine
            defaultExpandAll
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            treeData={gData}
            onSelect={handleWithCateSelect}
            onRightClick={handleWthCateRightSelect}
          />
          {/* <Button onClick={handleCancle} style={{marginLeft: '20px'}}>取消</Button>
          <Button type="primary" onClick={handleSubmit}>确认</Button> */}
      </Drawer>
      <ModifyInfo isModify={isModify} modifyVisible={modifyVisible} modifyOk={handleEditCateNameOK} createOk={handleCreateCateOK} modifyCancle={handleEditCateNameCacle}></ModifyInfo>
    </>
  );
}