import React, { useState, useEffect  } from "react";
import './index.less';
import  MenuCon from "./MenuCon/index";
import { getAllMenuAPI } from "../API/api";

export default function MainWrap() {

    const [menus, setMenus] = useState([])
    useEffect(() => {
      getAllMenus()
    }, [])


    // 获取目录
    const getAllMenus =  async ()=> {
      const res = await getAllMenuAPI()
      setMenus(res)
    }
    return (
        <div className="editContent">
          <MenuCon allMenus={menus}></MenuCon>
        </div>
    )
}