import React from "react";
import { Form, Input, Button, Checkbox, message } from 'antd';
import { login } from "../../API/index";
import  md5 from 'md5'
import './index.less'

const layout = {
  labelCol: {
    span: 2,
		offset: 8
  },
  wrapperCol: {
    span: 4,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 8,
  },
};

export default function LoginForm (props) {
  const onFinish = (values) => {
    console.log('Success:', values);
		handleWithLogin(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
	const handleWithLogin = async (values) => {
		console.log('value:', values)
	  const pw =	md5(values.password, 'im-admin-think')
		values.password = pw
		const res = await login({...values})
		if(res.data) {
			message.success('登录成功～')
			// 展示默认文章内容
			props.loginFun(values)

		} else {
			message.error('登录失败～')
		}
	}

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="loginMain"
    >
      <Form.Item
        label="用户名"
        name="username"
        rules={[
          {
            required: true,
            message: '请输入用户域账号!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="密码"
        name="password"
        rules={[
          {
            required: true,
            message: '请输入用户域账号密码',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};