import React , {useState, useEffect } from 'react';
// import Script from 'react-load-script';
import './App.less';
import MainWrap from "./component/index";
import LoginForm from "./component/loginForm/index";

function App() {
    // const [showEditPart, setShowEditPart] = useState(true)
    const [isLogin, setLogin] = useState(false)
    useEffect(() => {
        document.body.setAttribute('data-mode', 'edit')
        let cookies = document.cookie
        if(cookies.indexOf('token') > -1) {
          setLogin(true)
        }
    }, [])
    // 用户登录判断,设置标识并存储
    const handleWithLogin = (val) => {
      val && setLogin(true)
    }
    return (
      <div id="mainContent">
        {isLogin ? 
        <MainWrap></MainWrap> :
        <LoginForm loginFun={handleWithLogin}></LoginForm> 
        }
      </div>
    )

    // return (
    //     <>
    //     {/* button处理区域 */}
    //     <div className='btn-wrap11'>
    //         <div onClick={handleWithEdit}>编辑</div>
    //         <div onClick={handleWithComplete}>完成</div>
    //         <div>选择主题颜色</div>
    //     </div>
    //      {/* 编辑展示区域 */}
    //     <div className="theme-list" style={{display: 'inline-block'}}></div>
    //     <div className="ui-layout-toggler" id="north-toggler"></div>
    //     <div className="ui-layout-south">
            
    //         <div className="ui-layout-center">
    //         {/* <div className="ui-layout-center" style={{ display: `${showEditPart ? 'inherit': 'none'}`}}> */}
    //             <div id="editor" style={{height: '800px'}} className='edit-class'></div>
    //         </div>
            

    //         <div id="east-toggler" className="ui-layout-toggler"></div>
          
    //         <div className="ui-layout-east">
    //             <article className="markdown-body" id="preview" data-open-title="Hide Preview" data-closed-title="Show Preview">
    //             </article>
    //         </div>
    //     </div>
        
    //     </>
    // )
}

export default App;
