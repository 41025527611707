import React, { useState, useEffect  } from "react";
import MDEditor from '@uiw/react-md-editor';
import './index.less';

export default function EditorContent(props) {

    const [content, setContent] = useState('')
    useEffect(() => {
      const { content } = props
      setContent(content)
    }, [props])
    
    const changeContent = (content) => {
      props.updateCon(content)
      setContent(content)
    }

    return (
      <>
        <div className="editContent">
          <MDEditor
            value={content}
            onChange={changeContent}
          />
          {/* <MDEditor.Markdown source={content} /> */}
        </div>

      </>
    )
}