import { get, post } from "./index";
// const apiHost = 'http://192.168.138.13:8360/'
// const apiHost = window.location.origin + '/api/'
const apiHost = 'https://cms.daqunchat.com/api/'
/* 
    获取对应文章ID内容 - 后台管理
    params: {
        id: 文章id
    }
    method: get
*/
export const getContentAPI = async (params = {}) => {    
   return await get(`${apiHost}cms/article/get	`, params)
}

/* 
    编辑更新文章
    params: {

    }
    method: post
*/
export const updateContentAPI = async (params = {}) => {
    return await post(`${apiHost}cms/article/update`, params)
}
/* 
    删除文章
    params: {

    }
    method: post
*/
export const deleteContentAPI = async (params = {}) => {
    return await post(`${apiHost}cms/article/delete`, params)
}
/* 
    新建文章
    params: {

    }
    method: post
*/
export const createContentAPI = async (params = {}) => {
    return await post(`${apiHost}cms/article/create`, params)
}

// 目录部分
/* 
    获取所有目录列表
    params: ''
    method: get
*/
export const getAllMenuAPI = async (params = {}) => {    
    return await get(`${apiHost}cms/cate/treeList`, params)
 }
/* 
    获取所有目录列表
    params: ''
    method: get
*/
export const getAllMenuListAPI = async (params = {}) => {    
    return await get(`${apiHost}cms/cate/list`, params)
 }
/* 
    编辑目录
    params: ''
    method: post
*/
export const updateMenuAPI = async (params = {}) => {    
    return await post(`${apiHost}cms/cate/update`, params)
 }
/* 
    添加目录
    params: ''
    method: post
*/
export const createMenuAPI = async (params = {}) => {    
    return await post(`${apiHost}cms/cate/create`, params)
 }
/* 
    删除目录
    params: ''
    method: post
*/
export const deleteMenuAPI = async (params = {}) => {    
    return await post(`${apiHost}cms/cate/delete`, params)
}

/* 
    修改当前目录父级元素
    params: {
        parent_id
        cate_id
    }
*/
export const changeCatePosAPI = async (params ={}) => {
    return await post(`${apiHost}cms/cate/changeParentCate`, params)
}
/* 
    修改当前文章父级元素
    params: {
        article_id
        cate_id
    }
*/
export const changeArticlePosAPI = async (params ={}) => {
    return await post(`${apiHost}cms/article/changeCate`, params)
}