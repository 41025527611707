import React, { useState } from 'react';
import { updateContentAPI, createContentAPI, deleteContentAPI, createMenuAPI } from "../../API/api";
import { Button, message, Modal, Form, Input} from 'antd';
import Toast from "zarm/lib/toast"
import "zarm/lib/toast/style/css"
import './index.less'
import MenuModal from '../MenuCon/MenuModal';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import HandleCate from "./handleCate";



export default function EditBtn(props) {
	console.log('editBtnPros:', props)
	const { currentData = {}, contentTitle = '', menus, content, fetchClickIdContent, reloadMenu, defaultId, updateEditFlag, isShowSaveTips, updateSaveTips} = props

	const [isShow, setIsShow] = useState(false)
	const [showKey, setShowKey] = useState('')
	const [isShowCate, setIsShowCate] = useState(false)
	const [modifyVisible1, setModifyVisible1] = useState(false)


	// 完成编辑- 保存update
	const handleWithUpdate = async () => {
		// status 0 -未发布，1 -已发布 2 - 已下架
		const res = await updateContentAPI({...currentData, content: content, status: 1, title: contentTitle})
		if (res.data) {
			console.log('data:', res, res.data)
			reloadMenu()
			Toast.show({content: '保存成功~', stayTime: 3000})
		} else {
			message.error(JSON.stringify(res.data.message))
		}
		updateEditFlag(true)
	}
	// 新建
	const handleWithCreate = async () => {
		// params: cate_id / title / desc / content / status
		setIsShow(true)

	}
	// 删除
	const handleWithDelete = async () => {
		const res = await deleteContentAPI({id: currentData.id})
		if(res.data) {
			message.success('删除成功~')
			window.location.reload()
		}
	}

	// 新建或保存文章 - 传递子组件重新render
	const okFun = async (saveNodeInfo, isSave) => {
		setIsShow(false)
		/* 
		
			1 cate_id	类目id	是	[string]	
			2	title	文章标题	是	[string]	
			3	desc	文章描述		[string]
			4	content	文章内容	是	[string]
			5	status 发布状态 文章状态，0:未发布，1:已发布，2:已下架
		*/
		let params = {
			cate_id: saveNodeInfo.cate_id,
			title: saveNodeInfo.title,
			desc: saveNodeInfo.desc,
			content: '请输入',
			status: saveNodeInfo.status || 0
		}
		console.log('nodeInfo', saveNodeInfo)
	
			const res = await createContentAPI({...params})
			if(res.data) {
				setShowKey(res.data)
				Toast.show({content: '新建成功～', stayTime: 3000})
				reloadMenu()
				fetchClickIdContent(res.data)
			}	else {
				message.error(JSON.stringify(res.message))
			}
		
	}
	const cancleFun = () => {
		setIsShow(false)
	}
	const confirm = (params) => {
	  Modal.confirm({
			title: '确认删除?',
			icon: <ExclamationCircleOutlined />,
			content: '删除后不可撤回,请谨慎操作',
			okText: '确认',
			okType: 'danger',
			cancelText: '取消',
			onOk() {
				console.log('OK');
				handleWithDelete()
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}
	const handleCateBtn = () => {
		setIsShowCate(true)
	}
	const cateCancle = () => {
		setIsShowCate(false)
	}
	const cateOK = () => {
		setIsShowCate(false)
	}

	// 直接离开
	const handleSaveCancle = () => {
		updateEditFlag(true)
		updateSaveTips(false)
	}
	// 去完成保存
	const handleSaveOK = () => {
		updateEditFlag(false)
		handleWithUpdate()
		updateSaveTips(false)
	}

		// 初始化时所有结构为空
	const handleNoCreateCateOK = async (infos) => {
      /* name	类目名称	是	[string]
      desc	类目描述	是	[string]
      weight	权重 
      parent_id	上级类目id */
			setModifyVisible1(false)
			let data = {
				name: infos.name,
				weight: infos.weight || '',
				parent_id: infos.cate_id || '',
				desc: infos.desc
			}
			const cRes = await createMenuAPI({...data})
			if (cRes.data) {
				message.success('创建成功～')
				window.location.reload()
			} else {
				message.error(JSON.stringify(cRes.message))
			}
	}

	const handleWithNoCate = () => {
		setModifyVisible1(true)
	}

	const handleNoCateCancle = () => {
		setModifyVisible1(false)
	}

	
	return (
		<div className="editBtnWrap">			
			<MenuModal isShow={isShow} showKey={showKey} handlePOK={okFun} handlePCancle={cancleFun}></MenuModal>
			<div>
				<Button onClick={handleCateBtn} type="ghost">操作目录</Button>
				<HandleCate vis={isShowCate} menus={menus} handleCateOK={cateOK} reloadMenu={reloadMenu} handleCateCancle={cateCancle}></HandleCate>
			</div>

			 <Button type="ghost" className="createBtn" onClick={handleWithNoCate}>新建根目录</Button>
			<Button type="ghost" className="createBtn" onClick={handleWithCreate}>新建文章</Button>
			{/* <Button type="ghost" className="eidtBtn" onClick={handleWithEdit}>编辑</Button> */}
			{currentData.id && <Button type="primary" size="middle" className="saveBtn" onClick={handleWithUpdate}>完成</Button>}
			{currentData.id && <Button type="default" size="middle" className="deleteBtn" onClick={confirm}>删除</Button>}
			<Modal 
      title="新建目录"
      visible={modifyVisible1}
      footer={null}
      destroyOnClose={true}
      // onOk={modifyModalOk}
      // onClose={modifyModalCancle}
      >
        <Form  
        name="basic"
        preserve={false}
        initialValues={{
          remember: true,
        }}
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        onFinish={handleNoCreateCateOK}
        onFinishFailed={handleNoCateCancle}>
          <Form.Item
          name="name"
          label="目录名"
          >
          <Input />
          </Form.Item>
          <Form.Item
          name="desc"
          label="目录描述"
          >
          <Input />
          </Form.Item>
          <Form.Item wrapperCol={{offset: 8}}>
            <Button type="ghost" onClick={handleNoCateCancle}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确认
            </Button>
          </Form.Item>
        </Form>
    </Modal>
			<Modal
					visible={isShowSaveTips}
					title="文章尚未保存, 是否直接离开？"
					okText="去完成保存"
					cancelText="不保存直接离开"
					onCancel={handleSaveCancle}
					onOk={handleSaveOK}
					>
					<Form	>
							<Form.Item>编辑内容是否保存，不保存直接离开则 编辑内容无效</Form.Item>		
					</Form>
				</Modal>
		</div>
	)
}