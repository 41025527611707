import React, {useState, useEffect } from 'react';
import { Menu, Layout, Input } from 'antd';
import { PicCenterOutlined } from '@ant-design/icons';
// import ReactMarkdown from 'react-markdown';
// import 'github-markdown-css/github-markdown.css'
import './index.less'
import  EditContent  from "../EditContent/index";
import { getAllMenuAPI, getContentAPI } from "../../API/api";
import EditBtn from '../EditBtn/index';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

export default function MenuCon(props) {

  const [current, setCurrent] = useState('')
  const [content, setContent] = useState('')
  const [menus, setMenus] = useState([])
	const [defaultId, setDefaultId] = useState(1)
	const [titleVal, setTitleVal] = useState('')
	const [currentData, setCurrentData] = useState({})
	const [isEditFlag, setEditFlag] = useState(true)
	const [isShowSaveTips, setIsShowSaveTips] = useState(false)
	const [clickEvent, setClickEvent] = useState({})

	useEffect(() => {
		setEditFlag(isEditFlag)
	}, [isEditFlag])

	useEffect(() => {
		const { allMenus } = props
		if (allMenus.length < 1) {
			reloadMenu()
		} else {
			setMenus(allMenus)
		}
		// 如有需要，可以在这里获取当前默认id，设置
	  allMenus.length && setDefaultId(allMenus[0].id)
	}, [props])

  // 切换展示内容 
  const handleClick = e => {
    console.log('click ', e);
    setCurrent(e.key)
		setClickEvent(e)
		reloadMenu()
		fetchClickIdContent(e.key || current)
  };
	// 重新加载menu
	const reloadMenu = async () => {
		const res = await getAllMenuAPI()
		setMenus(res)
	}
	// 切换文章且编辑内容后提醒用户已经保存发布
	const saveTips = async e => {
		!isEditFlag && setIsShowSaveTips(true)

		// 调用时间顺序问题，先触发点击，后取消，此时isEditFlag的设置还未执行到-
		setClickEvent(e)
		isEditFlag && handleClick(e) // first time
	}

	// 子组件更新内容保存后设置为true
	const updateEditFlag = (flag) => {
		setEditFlag(flag)
		flag && handleClick(clickEvent)
	}
	const updateSaveTips = (flag) => {
		setIsShowSaveTips(flag)
	}
	// 获取展示文章内容
  const fetchClickIdContent = async(key) => {
		setCurrent(key) // 新建时获取id 展示编辑内容
		let num1 = isNaN(key) ? key.replace(/[^\d]/g,'') : key // 请求文章时单独拎出number部分
		const res = await getContentAPI({id: num1})
		setTitleVal(res.title)
		reloadMenu()
		setCurrentData(res)
		setContent(res.content)
  }
	// 从子组件更新内容
	const handleWithUpdate = (c) => {
		setContent(c)
		setEditFlag(false)
	}

	// 填写标题
	const changeTitle = (e) => {
		setTitleVal(e.target.value)
	}
	// 操作循环目录
	const handleCateTraversal = (nodes) => {
		
		const { type } = nodes
		let cateDiv = <div></div>
		
		switch (type) {
			case 'cate':
				cateDiv = nodes.id && <SubMenu key={nodes.id} title={nodes.name}>{nodes.children && nodes.children.length > 0 ? nodes.children.map((node) => handleCateTraversal(node)) : ''}</SubMenu>
				break
			case 'article': 
				cateDiv = 	<Menu.Item key={nodes.id} title={nodes.title} >{nodes.title}</Menu.Item>
				break
			default:
				break;
		}
		return cateDiv

	}

	return (
		<div className="menuContent">
			<EditBtn currentData={currentData} defaultId={defaultId} menus={menus} isShowSaveTips={isShowSaveTips} updateSaveTips={updateSaveTips} updateEditFlag={updateEditFlag} content={content} reloadMenu={reloadMenu} contentTitle={titleVal} fetchClickIdContent={fetchClickIdContent}></EditBtn>
			<Layout>
				<Sider style={{backgroundImage: 'linear-gradient(#F3F9FE, #F4FAFE, #FAFDFE, #FDFEFF, #FDFEFF)'}}>
					{menus.map((items, index) => (
						<Menu
							theme="light"
							onClick={saveTips}
							defaultOpenKeys={[defaultId]}
							selectedKeys={[current]}
							style={{ width: 256, height: 'calc(100vh - 114px)' }}
							mode="inline"
							key={index}
						>
							<SubMenu key={items.id} title={items.name} icon={<PicCenterOutlined />} style={{height: '100%', overflowY: 'scroll', overflowX: 'hidden'}}>
								{
									// type为cate表示目录,article表文章,article是title，cate是name
									items.children && items.children.map((item, index) => (
										handleCateTraversal(item, index)
									))
								}
							</SubMenu>
						</Menu>
					))}
				</Sider>
				{/* 未点击编辑或新建时不出现  - id可以作为判断 */}
				{current && <Content>
					<div className="content-title">侧边栏标题: 
					<Input placeholder="请输入文章标题" allowClear={true} value={titleVal} onChange={changeTitle} onPressEnter={changeTitle}></Input>
					</div>
					<EditContent content={content} updateCon={handleWithUpdate}>
					</EditContent>
				</Content>}
			</Layout>
		</div>
	);
}