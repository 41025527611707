import {  Modal, Form, Input, Button } from 'antd';
import React, { useState, useEffect } from "react";
import "./index.less";


export default function ModifyInfo(props) {
  const { modifyVisible, modifyOk, modifyCancle, createOk,  isModify} = props
  const [formTitle, setFormTitle] = useState('')

  useEffect( () =>{
     let title1 = isModify ? '修改目录名' : '添加目录'
     setFormTitle(title1)
  }, [modifyVisible, isModify])

  const onFinish = (infos) => {
    if (infos.name) {
      isModify ? modifyOk(infos) : createOk(infos)
    } else {
      modifyCancle()
    }
  }
  const onFinishFailed = () => {
    console.log('修改失败')
    modifyCancle()
  }

  const handleCancle = () => {
    modifyCancle()
  }
  
  
  // 默认选中取父 - button来自form表单获取内容，非从modal绑定的ok和cancle事件
  return (
    <Modal 
      title={formTitle}
      visible={modifyVisible}
      footer={null}
      destroyOnClose={true}
      // onOk={modifyModalOk}
      // onClose={modifyModalCancle}
      >
        <Form  
        name="basic"
        preserve={false}
        initialValues={{
          remember: true,
        }}
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
          <Form.Item
          name="name"
          label="目录名"
          >
          <Input />
          </Form.Item>
          <Form.Item
          name="desc"
          label="目录描述"
          >
          <Input />
          </Form.Item>
          <Form.Item wrapperCol={{offset: 8}}>
            <Button type="ghost" onClick={handleCancle}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确认
            </Button>
          </Form.Item>
        </Form>
    </Modal>
  );
}